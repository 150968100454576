<template>
  <div class="container">
    <div class="head">
      <div class="logo-title">诚信广交天下友</div>
      <div class="logo">
        <img src="../assets/binli-logo.png" alt="" />
      </div>
      <div class="logo-title">HS助您赢万金</div>
    </div>
    <div class="slider">
      <swiper ref="mySwiper" :options="swiperOptions" class="swiper">
        <swiper-slide
          v-for="(image, index) in images"
          :key="index"
          class="swiper-item"
        >
          <img :src="qnyAddress + image.imageUrl" alt="" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="content">
      <div class="download" @click="onDownload">
        <img src="../assets/yxxz.png" alt="" />
      </div>
      <div class="add-four">
        <div>
          <img src="../assets/add01.jpg" alt="" />
        </div>
        <div>
          <img src="../assets/add03.jpg" alt="" />
        </div>
        <div style="margin-bottom: 0;">
          <img src="../assets/add02.jpg" alt="" />
        </div>
        <div style="margin-bottom: 0;">
          <img src="../assets/add04.jpg" alt="" />
        </div>
      </div>
      <div class="news">
        <div class="text-wrapper">
          <div class="text">
            {{ rollAd }}
          </div>
        </div>
      </div>
      <div class="slogan">
        <img src="../assets/cx.png" alt="" />
      </div>
    </div>
    <div class="foot">
      <div class="merit">
        <div class="merit-item">
          <div class="image">
            <img src="../assets/xy01.png" alt="" />
          </div>
          <div class="info">
            <h4>信誉第一</h4>
            <div>信誉为经营之本</div>
          </div>
        </div>
        <div class="merit-item">
          <div class="image">
            <img src="../assets/xy02.png" alt="" />
          </div>
          <div class="info">
            <h4>高佣金代理</h4>
            <div>返超高佣金比例</div>
          </div>
        </div>
        <div class="merit-item">
          <div class="image">
            <img src="../assets/xy03.png" alt="" />
          </div>
          <div class="info">
            <h4>安全保障</h4>
            <div>银行级安全加密</div>
          </div>
        </div>
      </div>
      <div class="desc">
        <div>
          HS郑重提示：彩票有风险，投注需谨慎<br />

          不向未满18周岁的青少年出售彩票<br />

          Copyright ? 2020 HS. All rights reserved
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import axios from 'axios'
Vue.prototype.axios = axios

export default {
  name: 'Index',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      images: [],
      rollAd: '',
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true, //允许分页点击跳转
        },
      },
      translatePxiel: 0,
      requestId: '',
      qnyAddress: '',
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  mounted() {
    this.getData()
    this.animateNotices()
    this.$once('hook:beforeDestroy', () => {
      window.cancelAnimationFrame(this.requestId)
    })
  },
  methods: {
    getData() {
      axios
        .get('https://cc.sapcasda.cn/querySystemInfo.do')
        .then((res) => {
          this.qnyAddress = res.data.returnMap.qnyAddress || ''
        })
        .catch((err) => {
          alert(err.message)
        })
      axios
        .get('https://cc.sapcasda.cn/indexPage.do')
        .then((res) => {
          this.images = res.data.returnMap.bannerList || []
          this.rollAd =
            res.data.rollAd || '尊敬的用户，感谢您一直以来对我们的支持！'
        })
        .catch((err) => {
          alert(err.message)
        })
    },
    animateNotices() {
      const textWrapperEl = document.querySelectorAll('.text-wrapper')[0]
      const textWrapperWidth = textWrapperEl.offsetWidth
      const texts = document.querySelectorAll('.text')
      let noticesWidth = 0
      texts.forEach((e) => {
        noticesWidth += e.offsetWidth
      })
      this.translatePxiel -= 1.5
      textWrapperEl.style.transform = `translateX(${this.translatePxiel}px)`
      if (-this.translatePxiel > noticesWidth) {
        this.translatePxiel = textWrapperWidth
      }
      this.requestId = window.requestAnimationFrame(this.animateNotices)
    },
    onDownload() {
      window.open('https://down.yyqqv.com:7277/GWIy6.html', '_blank')
    },
  },
}
</script>

<style lang="scss">
.container {
  .head {
    width: 100%;
    height: 17.33vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vw 4vw;
    box-sizing: border-box;
    .logo-title {
      height: 8vw;
      line-height: 8vw;
      border-top: solid 0.1vw rgba(190, 160, 105, 0.2);
      border-bottom: solid 0.1vw rgba(190, 160, 105, 0.2);
      color: rgba(190, 160, 105);
      font-size: 3.73vw;
      font-family: 华文行楷;
      letter-spacing: 0.5vw;
      padding: 0 1.5vw;
    }
    .logo {
      width: 13vw;
      height: 13vw;
      border-radius: 2vw;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .slider {
    width: 100%;
    height: 46.67vw;
    .swiper {
      width: 100%;
      height: 100%;
      .swiper-item {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .swiper-pagination-bullet {
        width: 2.4vw;
        height: 2.4vw;
        background: #ffd739;
        opacity: 1;
        border: 1px solid #fff;
      }
      .swiper-pagination-bullet-active {
        width: 2.4vw;
        height: 2.4vw;
        background-color: #ed5f50;
        opacity: 1;
      }
    }
  }
  .content {
    background: #0d172a;
    padding-bottom: 4vw;
    .download {
      width: 95vw;
      height: 17.33vw;
      margin: 4vw auto 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .add-four {
      width: 95vw;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      div {
        width: 46.13vw;
        height: 24vw;
        margin-bottom: 2.5vw;
        img {
          width: 100%;
          height: 100%;
        }
      }
      div:nth-child(2n) {
        margin-left: 2.6vw;
      }
    }
    .news {
      width: 90vw;
      height: 20vw;
      line-height: 20vw;
      margin: 0 auto;
      overflow: hidden;
      .text-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        .text {
          color: #f5c67d;
          font-size: 8vw;
          font-family: 'DINPro-Medium';
          white-space: nowrap;
          margin-right: 10vw;
          letter-spacing: 1vw;
        }
      }
    }
    .slogan {
      width: 74.13vw;
      height: 8.53vw;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .foot {
    .merit {
      height: 21.33vw;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #262b35;
      border-bottom: 1px solid #262b35;
      padding: 0 1vw;
      .merit-item {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 1vw;
        .image {
          width: 8.53vw;
          height: 8.53vw;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          margin-left: 1.5vw;
          font-family: 微软雅黑;
          h4 {
            margin: 0;
            color: #f5c67d;
            font-size: 3.2vw;
            text-align: left;
          }
          div {
            width: 100%;
            color: #ccc;
            font-size: 2.67vw;
            text-align: left;
          }
        }
      }
    }
    .desc {
      height: 21.33vw;
      line-height: 5.33vw;
      color: #ccc;
      font-size: 3.2vw;
      font-family: 微软雅黑;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
